<template>
    <div
        class="p-3 mb-2 bg-light"
        style="cursor: pointer"
        @click="checkNotification"
    >
        <p class="mb-1">{{ item.created_at | parseDate }} <span class="text-primary font-weight-bold">{{ item.header }}</span>
        </p>
        <div class="h6 m-0">{{ item.body }}</div>
    </div>
</template>

<script>
export default {
    name: 'Notification',
    props: {
        item: Object
    },
    computed: {
        url () {
            const config = this.item.config_data
            if (config.entity === 'order') {
               return `/purchase-orders/${config.ids[0]}`
            }
            if (config.entity === 'consignment') {
                return `/consignment-notes/${config.ids[0]}`
            }
            if (config.entity === 'payment-register') {
                return `/registries/${config.ids[0]}`
            }
            if (config.entity === 'provider-order') {
                return `/provider/purchase-orders/${config.ids[0]}`
            }
            if (config.entity === 'consignment-register') {
                return `/consignment-notes-registries/${config.ids[0]}`
            }
            if (config.entity === 'organization_notification') {
                return `/provider-notifications/${config.ids[0]}`
            }
            if (config.entity === 'price-negotiation') {
                return `/price-negotiations/${config.ids[0]}`
            }
            if (config.entity === 'price-negotiation-comment') {
                return `/price-negotiations/${config.ids[0]}`
            }
            if (config.entity === 'request-addition-nomenclature') {
                return `/nsi-list/${config.ids[0]}`
            }
            if (config.entity === 'provider-order/requirement-corrections') {
                return `/provider/purchase-orders/${config.ids[0]}?tab=2`
            }
            return '/'
        }
    },
    methods: {
        checkNotification() {
            this.$router.push(this.url)
            this.$store.dispatch('deleteNotification', this.item.id)
        }
    }
}
</script>
